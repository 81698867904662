<template>
    <RedirectDeposit :playerCurrency="playerCurrency"
                     :amount="amount"
                     :processorName="processorName"
                     :processorType="processorType"
                     :walletType="walletType"
                     :customerId="customerId"
                     :ipAddress="ipAddress"
                     :language="language"
                     :brandUrl="brandUrl"
                     :playerCurrencySymbol="playerCurrencySymbol"
                     :minDeposit="minDeposit"
                     :maxDeposit="maxDeposit"
    >

    </RedirectDeposit>

</template>

<script>

    export default {
        name: 'EutellerDeposit',
        data() {
            return {
                showLoader:true,
                paymentOption: null,
                language: null,
                customerId: null,
                ipAddress: null,
                playerCurrency: null,
                playerCurrencySymbol: null,
                brandUrl: null,
                amount: null,
                minDeposit: "5",
                maxDeposit: "1000000",
                limitReached: null,
                submitStatus: null,
                processorName: "SAFECHARGE",
                processorType: "EWALLET",
                walletType : "EUTELLER",
                showBankSection: false,
            }
        },
        components: {
            RedirectDeposit: () => import('../../others/RedirectDeposit')
        },
        mounted() {
            this.paymentOption = this.$router.history.current.query.paymentOption;
            this.language = this.$router.history.current.query.language;
            this.customerId = this.$router.history.current.query.customerId;
            this.ipAddress = this.$router.history.current.query.ipAddress;
            this.playerCurrency = this.$router.history.current.query.playerCurrency;
            this.playerCurrencySymbol = this.$router.history.current.query.playerCurrencySymbol;
            this.amount = parseInt(this.$router.history.current.query.amount);
            this.brandUrl = this.$router.history.current.query.brandUrl;
            this.accountInvalid = this.$router.history.current.query.errorCode;
            if(this.$router.history.current.query.minDeposit){
              this.minDeposit = this.$router.history.current.query.minDeposit;
            }
            if(this.$router.history.current.query.maxDeposit){
              this.maxDeposit = this.$router.history.current.query.maxDeposit;
            }
        }
    }
</script>
